<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="org">
      <v-card-title class="headline">App configuration for {{org.name}}</v-card-title>

      <v-card-text>      
        <p>
          Connect this organization with a white-label customized app.
        </p>

        <v-alert v-if="hasApp" type="success">
          <p>This organization is connected with the <strong>{{appBundleId}}</strong> app.</p>
        </v-alert>
        <div v-if="hasApp">
          <p>View the app store pages (link will start working once the app is published and approved in the app stores).</p>
          <p>
            <a class="mr-2" :href="`https://apps.apple.com/app/id${appAppleId}`" target="_blank"><img style="max-width:100%;" alt='Download on the App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png'/></a>
            <a :href="`https://play.google.com/store/apps/details?id=${appBundleId}`" target="_blank"><img style="max-width:215px; margin: -15px;" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
          </p>
        </div>

        <div v-if="!hasApp">
          <v-alert type="info">
            <p>This event is not yet connected with a customized app. Interested in having a custom app for your organization? Contact Us to explore possibilities!</p>
          </v-alert>

          <v-dialog max-width="400px">
            <template v-slot:activator="{ on }">
              <v-btn outlined color="primary" class="mr-4" v-on="on"><v-icon small class="mr-2">fa fa-cogs</v-icon> Configure Custom App</v-btn>
            </template>
            <v-card>
              <v-card-title>App Configuration</v-card-title>
              <v-card-text>
                Please contact support to help you configure this correctly.
              </v-card-text>
              <v-card-text>
                <v-text-field
                  v-model="appConfig.firebase_project_id"
                  label="Firebase Project ID"
                  />
                <v-text-field
                  v-model="appConfig.firebase_dynamic_link_domain"
                  label="Firebase Dynamic Link Url (e.g. 'XYZ.page.link')"
                  />
                <v-text-field
                  v-model="appConfig.bundle_id"
                  label="App Bundle ID"
                  />
                <v-text-field
                  v-model="appConfig.apple_store_id"
                  label="Apple App Store ID"
                  />
                <v-file-input 
                  v-model="appConfig.file"
                  label="Upload Firebase service principal private key .json file"
                  />

              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn color="secondary" text @click="joinAnotherDialog = false">{{$t('shared.cancel')}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="configureApp">{{$t('shared.confirm')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>              
          
        </div>
      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import OrgUtil from "@/util/orgUtil";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "IntegrationsApp",
  components: {
  },
  props: {
    org: Object,
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      appConfig: {},
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {

    async loadData() {
      if (this.user) {
      }
    },

    async configureApp() {
      let formData = new FormData()
      formData.append("file", this.appConfig.file, this.appConfig.file.name);
      formData.append("firebase_project_id", this.appConfig.firebase_project_id);
      formData.append("firebase_dynamic_link_domain", this.appConfig.firebase_dynamic_link_domain);
      formData.append("bundle_id", this.appConfig.bundle_id);
      formData.append("apple_store_id", this.appConfig.apple_store_id);
      const response = (await orgManagerService.integrationsConfigureApp(this.org.id, formData)).data;
      await this.loadData();
      this.$helpers.toastResponse(this, response, 'Successfully configured app.');

      if (response.status === 'OK') {
        this.orgUtil.refresh();
      }
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.org ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
        { text: 'Integrations', disabled: true },
        { text: 'App', disabled: true },
      ];
    },
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    appBundleId() {
      return (this.org && this.org.meta && this.org.meta.find(x => x.key == 'app.bundle_id')||{}).val_str;
    },
    appAppleId() {
      return (this.org && this.org.meta && this.org.meta.find(x => x.key == 'app.apple_store_id')||{}).val_str;
    },
    hasApp() {
      return this.org && this.org.meta && this.org.meta.some(x => x.key == 'app.bundle_id');
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

